import { SeasonAPI } from "../apis/SeasonAPI"
import {
  getCompetitionPageUrl,
  getCompetitionSeasonGroupPageUrl,
  getCompetitionSeasonGroupTurnPageUrl,
  getCompetitionSeasonPageUrl,
} from "./urls"
import { LocalizedCompetition, NavigationCompetition, SimplifiedCompetition } from "../types/competition"
import { CompetitionSeasonAPI } from "../apis/CompetitionSeasonAPI"
import { LocalizedSeason } from "../types/season"
import { GroupAPI } from "../apis/GroupAPI"
import moment from "moment"
import { SEO } from "../types/next.js"
import { LocalizedTurn } from "../types/turn"
import { LocalizedGroup } from "../types/group"
import { getDefaultCanonicalData } from "./canonicalization"
import { omit, orderBy } from "lodash"
import { SeasonConstants } from "../constants/seasons"
import { NavigationTeam } from "../types/team"
import { CalciocomConfigHomeLocalizedData } from "types/calciocomConfigs"

export async function getCompetitionPartialSEO(
  competition: LocalizedCompetition,
  season: LocalizedSeason | null,
  group: LocalizedGroup | null,
  turn: LocalizedTurn | null,
  page: string,
  seasons?: LocalizedSeason[] | undefined,
  groups?: LocalizedGroup[] | undefined,
): Promise<Partial<SEO>> {
  const partialPage = page.split("/").pop()

  if (season) {
    const internalSeasons: LocalizedSeason[] = []
    if (seasons) {
      internalSeasons.push(...seasons)
    } else {
      internalSeasons.push(...(await SeasonAPI.getSeasonsByCompetitionId(competition.id)))
    }

    if (!internalSeasons || internalSeasons.length === 0) {
      return {
        canonicals: [getCompetitionPageUrl(competition.calciocomSlug!, "generale")],
        index: false,
        follow: true,
      }
    }
    let lastSeason = internalSeasons[0]

    const currentSeason = internalSeasons.find((season) => season.year === SeasonConstants.CURRENT_SEASON_YEAR)
    if (currentSeason) {
      lastSeason = currentSeason
    }

    let index = false
    if (lastSeason.id === season?.id) {
      index = true
    }

    if (group) {
      const internalGroups: LocalizedGroup[] = []
      if (groups) {
        internalGroups.push(...groups)
      } else {
        const competitionSeasonId = await CompetitionSeasonAPI.getIdByCompetitionIdAndSeasonId(
          competition.id,
          lastSeason.id,
        )

        internalGroups.push(...(await SeasonAPI.getPhaseGroups(competitionSeasonId)))
      }

      if (!internalGroups || internalGroups.length === 0) {
        return {
          canonicals: [getCompetitionPageUrl(competition.calciocomSlug!, "generale")],
          index: false,
          follow: true,
        }
      }

      let firstGroup: LocalizedGroup
      if (groups) {
        firstGroup = internalGroups[0]
      } else {
        firstGroup = await GroupAPI.getGroup(internalGroups[0].id)
      }

      if (turn) {
        const turns = firstGroup.turns
        if (!turns || turns.length === 0) {
          return {
            canonicals: [getCompetitionPageUrl(competition.calciocomSlug!, "generale")],
            index: false,
            follow: true,
          }
        }
        let firstTurn = turns[0]

        const futureTurns = turns.filter((turn) => turn.start && moment(turn.start).isAfter(moment()))
        if (futureTurns.length > 0) {
          firstTurn = futureTurns[0]
        }

        // if there is a turn with the same slug but related to a different season or group
        // then use it, otherwise use the automatically retrieved one
        const turnCalciocomSlug =
          turn &&
          turns.find((t) => t.calciocomSlug === turn.calciocomSlug) &&
          turn.calciocomSlug !== firstTurn.calciocomSlug
            ? turn.calciocomSlug!
            : firstTurn.calciocomSlug!

        return {
          canonicals: [
            index
              ? page
              : getCompetitionSeasonGroupTurnPageUrl(
                  competition.calciocomSlug!,
                  lastSeason.calciocomSlug!,
                  firstGroup.calciocomSlug!,
                  turnCalciocomSlug,
                  partialPage,
                ),
          ],
          index: index,
          follow: true,
        }
      } else {
        // if there is a group with the same slug but related to a different season
        // then use it, otherwise use the automatically retrieved one
        const groupCalciocomSlug =
          group &&
          internalGroups.find((g) => g.calciocomSlug === group.calciocomSlug) &&
          group.calciocomSlug !== firstGroup.calciocomSlug
            ? group.calciocomSlug!
            : firstGroup.calciocomSlug!

        return {
          canonicals: [
            index
              ? page
              : getCompetitionSeasonGroupPageUrl(
                  competition.calciocomSlug!,
                  lastSeason.calciocomSlug!,
                  groupCalciocomSlug,
                  partialPage,
                ),
          ],
          index: index,
          follow: true,
        }
      }
    } else {
      return {
        canonicals: [
          index
            ? page
            : getCompetitionSeasonPageUrl(competition.calciocomSlug!, lastSeason.calciocomSlug!, partialPage),
        ],
        index: index,
        follow: true,
      }
    }
  } else {
    return {
      canonicals: [getCompetitionPageUrl(competition.calciocomSlug!, partialPage)],
      index: true,
      follow: true,
    }
  }
}

export async function getCompetitionCanonicalUrls(
  crawUrls: string[],
  competition: LocalizedCompetition,
  season: LocalizedSeason | null,
  group: LocalizedGroup | null,
  turn: LocalizedTurn | null,
  page: string,
  seasons?: LocalizedSeason[] | undefined,
  groups?: LocalizedGroup[] | undefined,
) {
  if (crawUrls.includes(page)) {
    return [page]
  }

  const partialSeo = await getCompetitionPartialSEO(competition, season, group, turn, page, seasons, groups)

  return partialSeo.canonicals!
}

export async function getCompetitionCanonicalData(
  competition: LocalizedCompetition,
  season: LocalizedSeason | null,
  group: LocalizedGroup | null,
  turn: LocalizedTurn | null,
  page: string,
): Promise<Partial<SEO>> {
  const defaultCanonicalData = await getDefaultCanonicalData(page)
  if (defaultCanonicalData) {
    return defaultCanonicalData
  }

  return await getCompetitionPartialSEO(competition, season, group, turn, page)
}

export async function redirectCompetition(
  competition: LocalizedCompetition,
  page: string,
  goToGroupAndTurn = true,
  goToCompetitionSeason = true,
) {
  if (goToCompetitionSeason) {
    const seasons = await SeasonAPI.getSeasonsByCompetitionId(competition.id)
    if (!seasons || seasons.length === 0) {
      throw new Error("Competition's seasons not found")
    }
    let season = seasons[0]

    const currentSeason = seasons.find((season) => season.year === SeasonConstants.CURRENT_SEASON_YEAR)
    if (currentSeason) {
      season = currentSeason
    }

    if (goToGroupAndTurn) {
      const competitionSeasonId = await CompetitionSeasonAPI.getIdByCompetitionIdAndSeasonId(competition.id, season.id)

      const phaseGroups = await SeasonAPI.getPhaseGroups(competitionSeasonId)
      if (!phaseGroups || phaseGroups.length === 0) {
        return {
          redirect: {
            destination: getCompetitionSeasonPageUrl(competition.calciocomSlug!, season.calciocomSlug!, "generale"),
            permanent: false,
          },
        }
      }

      const group = await GroupAPI.getGroup(phaseGroups[0].id)

      const turns = group.turns
      if (!turns || turns.length === 0) {
        throw new Error("Competition's season's turns not found")
      }
      let turn = turns[0]

      if (turns.filter((t) => t.start).length > 0) {
        const orderedTurns = orderBy(
          turns.filter((turn) => turn.start && moment(turn.start).add(1, "d").isAfter(moment())),
          ["start"],
          ["asc"],
        )
        if (orderedTurns.length > 0) {
          turn = orderedTurns[0]
        }
      }

      return {
        redirect: {
          destination: getCompetitionSeasonGroupTurnPageUrl(
            competition.calciocomSlug!,
            season.calciocomSlug!,
            group.calciocomSlug!,
            turn.calciocomSlug!,
            page,
          ),
          permanent: false,
        },
      }
    } else {
      return {
        redirect: {
          destination: getCompetitionSeasonPageUrl(competition.calciocomSlug!, season.calciocomSlug!, page),
          permanent: false,
        },
      }
    }
  } else {
    return {
      redirect: {
        destination: getCompetitionPageUrl(competition.calciocomSlug!, page),
        permanent: false,
      },
    }
  }
}

export async function getRedirectCompetition(competition: LocalizedCompetition) {
  const seasons = await SeasonAPI.getSeasonsByCompetitionId(competition.id)
  if (!seasons || seasons.length === 0) {
    throw new Error("Competition's seasons not found")
  }
  let season = seasons[0]

  const currentSeason = seasons.find((season) => season.year === SeasonConstants.CURRENT_SEASON_YEAR)
  if (currentSeason) {
    season = currentSeason
  }
  if (!season) {
    return {
      redirect: {
        destination: `/campionato/${competition.calciocomSlug}`,
        permanent: false,
      },
    }
  }

  const competitionSeasonId = await CompetitionSeasonAPI.getIdByCompetitionIdAndSeasonId(competition.id, season.id)

  const phaseGroups = await SeasonAPI.getPhaseGroups(competitionSeasonId)
  if (!phaseGroups || phaseGroups.length === 0) {
    return {
      redirect: {
        destination: getCompetitionSeasonPageUrl(competition.calciocomSlug!, season.calciocomSlug!, "generale"),
        permanent: false,
      },
    }
  }

  //order phase by id take the max
  const group = await GroupAPI.getGroup(phaseGroups.sort((a, b) => b.id - a.id)[0].id)

  const turns = group.turns
  if (!turns || turns.length === 0) {
    throw new Error("Competition's season's turns not found")
  }
  let turn = turns[turns.length - 1]

  if (turns.filter((t) => t.start).length > 0) {
    const orderedTurns = orderBy(
      turns.filter((turn) => turn.start && moment(turn.start).add(1, "d").isAfter(moment())),
      ["start"],
      ["asc"],
    )
    if (orderedTurns.length > 0) {
      turn = orderedTurns[0]
    }
  }
  return {
    competitionSlug: competition.calciocomSlug!,
    seasonSlug: season.calciocomSlug!,
    groupSlug: group.calciocomSlug!,
    turnSlug: turn.calciocomSlug!,
  }
}

export async function redirectCompetitionSeason(
  competition: LocalizedCompetition,
  season: LocalizedSeason,
  page: string,
  goToGroupAndTurn = true,
) {
  if (goToGroupAndTurn) {
    const competitionSeasonId = await CompetitionSeasonAPI.getIdByCompetitionIdAndSeasonId(competition.id, season.id)

    const phaseGroups = await SeasonAPI.getPhaseGroups(competitionSeasonId)
    if (!phaseGroups || phaseGroups.length === 0) {
      if (!phaseGroups || phaseGroups.length === 0) {
        return {
          redirect: {
            destination: getCompetitionSeasonPageUrl(competition.calciocomSlug!, season.calciocomSlug!, "generale"),
            permanent: false,
          },
        }
      }
    }
    const group = await GroupAPI.getGroup(phaseGroups[0].id)

    const turns = group.turns
    if (!turns || turns.length === 0) {
      throw new Error("Competition's season's turns not found")
    }
    let turn = turns[0]

    const futureTurns = turns.filter((turn) => turn.start && moment(turn.start).isAfter(moment()))
    if (futureTurns.length > 0) {
      turn = futureTurns[0]
    }

    return {
      redirect: {
        destination: getCompetitionSeasonGroupTurnPageUrl(
          competition.calciocomSlug!,
          season.calciocomSlug!,
          group.calciocomSlug!,
          turn.calciocomSlug!,
          page,
        ),
        permanent: false,
      },
    }
  } else {
    return {
      redirect: {
        destination: getCompetitionSeasonPageUrl(competition.calciocomSlug!, season.calciocomSlug!, page),
        permanent: false,
      },
    }
  }
}

export async function getRedirectCompetitionSeason(competition: LocalizedCompetition, season: LocalizedSeason) {
  const competitionSeasonId = await CompetitionSeasonAPI.getIdByCompetitionIdAndSeasonId(competition.id, season.id)

  const phaseGroups = await SeasonAPI.getPhaseGroups(competitionSeasonId)
  if (!phaseGroups || phaseGroups.length === 0) {
    if (!phaseGroups || phaseGroups.length === 0) {
      return {
        redirect: {
          destination: getCompetitionSeasonPageUrl(competition.calciocomSlug!, season.calciocomSlug!, "generale"),
          permanent: false,
        },
      }
    }
  }
  const group = await GroupAPI.getGroup(phaseGroups[0].id)

  const turns = group.turns
  if (!turns || turns.length === 0) {
    throw new Error("Competition's season's turns not found")
  }
  let turn = turns[0]

  const futureTurns = turns.filter((turn) => turn.start && moment(turn.start).isAfter(moment()))
  if (futureTurns.length > 0) {
    turn = futureTurns[0]
  }
  return {
    competitionSlug: competition.calciocomSlug!,
    seasonSlug: season.calciocomSlug!,
    groupSlug: group.calciocomSlug!,
    turnSlug: turn.calciocomSlug!,
  }
}

export async function redirectCompetitionSeasonGroup(
  competition: LocalizedCompetition,
  season: LocalizedSeason,
  groupCalciocomSlug: string,
  page: string,
  goToTurn = true,
) {
  if (goToTurn) {
    const competitionSeasonId = await CompetitionSeasonAPI.getIdByCompetitionIdAndSeasonId(competition.id, season.id)

    const phaseGroups = await SeasonAPI.getPhaseGroups(competitionSeasonId)
    if (!phaseGroups || phaseGroups.length === 0) {
      throw new Error("Competition's season's groups not found")
    }
    const group = await GroupAPI.getGroup(
      phaseGroups.find((phaseGroup) => phaseGroup.calciocomSlug === groupCalciocomSlug)!.id,
    )

    const turns = group.turns
    if (!turns || turns.length === 0) {
      throw new Error("Competition's season's turns not found")
    }
    const turn = turns[0]

    return {
      redirect: {
        destination: getCompetitionSeasonGroupTurnPageUrl(
          competition.calciocomSlug!,
          season.calciocomSlug!,
          group.calciocomSlug!,
          turn.calciocomSlug!,
          page,
        ),
        permanent: false,
      },
    }
  } else {
    return {
      redirect: {
        destination: getCompetitionSeasonGroupPageUrl(
          competition.calciocomSlug!,
          season.calciocomSlug!,
          groupCalciocomSlug,
          page,
        ),
        permanent: false,
      },
    }
  }
}

export function getPriorityOrderCompetition(comp: NavigationCompetition) {
  let returnValue = 100

  if (comp?.name?.startsWith("UEFA EURO 2024")) {
    returnValue = 1
  }

  if (comp?.name?.startsWith("Serie ")) {
    returnValue = 2
  }

  if (comp?.name?.startsWith("Premier ")) {
    returnValue = 3
  }

  if (comp?.name?.startsWith("Primera ")) {
    returnValue = 4
  }

  if (comp?.name == "Bundesliga") {
    returnValue = 5
  }

  if (comp?.name?.startsWith("Ligue")) {
    returnValue = 6
  }

  if (comp?.name?.startsWith("Primeira")) {
    returnValue = 7
  }

  if (comp?.name == "Eredivise") {
    returnValue = 8
  }

  return returnValue
}

export function getPriorityOrderTeam(team: NavigationTeam) {
  return team?.name?.startsWith("Italia") ? 0 : 1
}
export const simplifyHomeData = (
  homeData: CalciocomConfigHomeLocalizedData[],
): Omit<CalciocomConfigHomeLocalizedData, "groups">[] => {
  return homeData.map((data) => omit(data, "groups"))
}

export const simplifyCompetition = (competition: LocalizedCompetition): SimplifiedCompetition => {
  return {
    id: competition.id,
    calciocomSlug: competition.calciocomSlug,
    name: competition.name,
    countryId: competition.countryId,
    country: competition.country,
    imageId: competition.imageId,
    image: competition.image,
    type: competition.type,
    gender: competition.gender,
    metaTitle: competition.metaTitle,
    metaDescription: competition.metaDescription,
  }
}
