import Script from "next/script"
import React from "react"
import { SEO } from "../../../types/next.js"

export const CalcioComScript = ({ seo }: { seo?: SEO }) => {
  return (
    <>
      <Script
        id={"adform-google-pixel"}
        strategy={"lazyOnload"}
        dangerouslySetInnerHTML={{
          __html: `
        window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
        window._adftrack.push({
          HttpHost: 'track.adform.net',
          pm: 2604931,
          divider: encodeURIComponent('|'),
          pagename: encodeURIComponent('Planetwin_Retargeting-Calcio_2024')
        });
      (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`,
        }}
      />
      <Script
        id={"facebook-google-pixel"}
        strategy={"lazyOnload"}
        dangerouslySetInnerHTML={{
          __html: `      
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '530885262064023');
        fbq('track', 'PageView');`,
        }}
      />
      <Script
        id={"google-tag-manager"}
        strategy={"lazyOnload"}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY}');`,
        }}
      />
      <Script
        id={`google-tag-analytics-3`}
        strategy={"lazyOnload"}
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY_2}`}
      />
      <Script
        id={`google-tag-analytics-4`}
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY_2}');
          `,
        }}
      />
      {seo?.publishedAt && (
        <Script
          id={"schemaorg"}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
                {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "headline": "${seo?.title}",
          "image": [
            "${seo?.image}"
          ],
          "datePublished": "${seo?.publishedAt}",
          "dateModified": "${seo?.publishedAt}",
          "author": {
              "@type": "Person",
              "name": "${seo?.author?.name || "Gianfranco Pastore"}",
              "url": "${seo?.author?.link || "https://www.linkedin.com/in/gianfrancopastore/"}"
          },
          "publisher": {
              "@type": "Organization",
               "name": "Calcio.com",
               "logo": {
                  "@type": "ImageObject",
                   "url": "https://www.calcio.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fcalcio-logo-512.709fbc35.png&w=256&q=100"
               }
          },
          "articleSection": "Quote e pronostici",
          "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${seo.url}"
        }
        }`,
          }}
        />
      )}

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY}`}
          height={"0"}
          width={"0"}
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=530885262064023&ev=PageView&noscript=1"
        />
      </noscript>
      <noscript>
        <p style={{ margin: 0, padding: 0, border: 0 }}>
          <img
            src="https://track.adform.net/Serving/TrackPoint/?pm=2604931&ADFPageName=Planetwin_Retargeting-Calcio_2024&ADFdivider=|"
            width="1"
            height="1"
            alt=""
          />
        </p>
      </noscript>
    </>
  )
}
