import { Layout, Menu } from "antd"
const { Sider } = Layout
const { SubMenu } = Menu
import { BackofficeLayoutConstants } from "constants/backofficeLayoutConstants"
import { useRouter } from "next/router"
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined"
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined"
import DashboardOutlined from "@ant-design/icons/lib/icons/DashboardOutlined"
import calciocomLogo from "@assets/images/calcio-logo-512.png"
import Image from "next/image"
import React from "react"
import DollarCircleOutlined from "@ant-design/icons/lib/icons/DollarCircleOutlined"

type NavigationElement = {
  path: string
  name: string
  icon: React.ReactNode
  paths?: NavigationElement[]
}

const navigation: NavigationElement[] = [
  {
    name: "Dashboard",
    path: "/backoffice",
    icon: <DashboardOutlined />,
  },
  {
    name: "News",
    path: "/backoffice/news",
    icon: <FileTextOutlined />,
  },
  {
    name: "Bonus bookmaker",
    path: "/backoffice/bookmaker-bonuses",
    icon: <DollarCircleOutlined />,
  },
  {
    name: "Guide",
    path: "/backoffice/guides",
    icon: <FileTextOutlined />,
  },
  {
    name: "Quote Maggiorate",
    path: "/backoffice/quote-maggiorate",
    icon: <FileTextOutlined />,
  },
  {
    name: "Squadre",
    path: "/backoffice/teams",
    icon: <FileTextOutlined />,
  },
  {
    name: "Competizioni",
    path: "/backoffice/competitions",
    icon: <FileTextOutlined />,
  },
  {
    name: "Landing",
    path: "/backoffice/landing",
    icon: <FileTextOutlined />,
  },
  {
    name: "calcio.com",
    path: "/",
    icon: <ArrowLeftOutlined />,
  },
]

function BackofficeSider({
  isResponsive,
  onCollapse = () => {
    return
  },
}: {
  isResponsive?: boolean
  onCollapse?: (collapsed: boolean, type?: string) => void
}) {
  const router = useRouter()

  const menuCurrentKey = router.pathname

  return (
    <Sider
      collapsible
      breakpoint="lg"
      collapsedWidth={BackofficeLayoutConstants.BACKOFFICE_COLLAPSED_SIDER_WIDTH}
      onCollapse={(collapsed, type) => {
        onCollapse(collapsed, type)
      }}
      className={`backoffice-sider ${isResponsive ? "is-responsive" : ""}`}
    >
      <div className="logo">
        <Image
          src={calciocomLogo}
          width="140"
          className="main-logo"
          height="39"
          alt="calcio.com"
          onClick={() => {
            router.push("/backoffice")
          }}
        />
      </div>
      <Menu
        theme="dark"
        selectedKeys={[menuCurrentKey]}
        defaultOpenKeys={navigation
          .filter((navigationElement) => {
            return (
              navigationElement.paths?.find((nestedNavigationElement) => {
                return nestedNavigationElement.path === menuCurrentKey
              }) || navigationElement.path === menuCurrentKey
            )
          })
          .map((navigationElement) => navigationElement.path)}
        mode="inline"
        onSelect={(selectInfo) => {
          router.push(selectInfo.key)
        }}
      >
        {navigation.map((navigationElement) => {
          if (!navigationElement.paths || navigationElement.paths.length === 0) {
            return (
              <Menu.Item key={navigationElement.path} icon={navigationElement.icon} title={navigationElement.name}>
                {navigationElement.name}
              </Menu.Item>
            )
          } else {
            return (
              <SubMenu key={navigationElement.path} icon={navigationElement.icon} title={navigationElement.name}>
                {navigationElement.paths.map((nestedNavigationElement) => {
                  return (
                    <Menu.Item
                      key={nestedNavigationElement.path}
                      icon={nestedNavigationElement.icon}
                      title={nestedNavigationElement.name}
                    >
                      {nestedNavigationElement.name}
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )
          }
        })}
      </Menu>
    </Sider>
  )
}

export { BackofficeSider }
